<template>
  <!-- 系统设置 - 资讯公告 -->
  <div class="pageContol advisoryNotice">
    <div class="framePage">
      <!-- 面包屑 -->
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">系统设置</a>
          <i class="el-icon-arrow-right"></i>
          <a href="javascript:;" @click="init" class="cur-a">资讯公告</a>
        </span>
      </div>
      <div class="framePage-body">
        <!-- 检索条件 -->
        <div class="operationControl">
          <div class="searchbox">
            <div title="标题" class="searchboxItem ci-full">
              <span class="itemLabel">标题:</span>
              <el-input
                v-model="searchData.title"
                size="small"
                placeholder="请输入查询标题"
                clearable
              />
            </div>
            <div title="发布系统" class="searchboxItem ci-full">
              <span class="itemLabel">发布系统:</span>
              <el-select
                v-model="searchData.terminal"
                placeholder="请选择"
                size="small"
                clearable
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <div title="发布状态" class="searchboxItem ci-full">
              <span class="itemLabel">发布状态:</span>
              <el-select
                v-model="searchData.advisoryState"
                placeholder="请选择"
                clearable
                size="small"
              >
                <el-option
                  v-for="item in advisoryOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <div class="df">
              <el-button type="primary" class="bgc-bv" round @click="getData()"
                >查询</el-button
              >
              <el-button type="primary" class="bgc-bv" round @click="seeInfo()"
                >新增</el-button
              >
            </div>
          </div>
        </div>
        <!-- 列表 -->
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :span-method="objectSpanMethod"
              :header-cell-style="tableHeader"
              :cell-style="cellbgc"
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                fixed
              />
              <el-table-column
                label="标题"
                align="left"
                prop="advisoryName"
                show-overflow-tooltip
                min-width="300"
              ></el-table-column>
              <el-table-column
                label="发布系统"
                align="center"
                prop="terminal"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <span v-if="scope.row.terminal == '1'">App</span>
                  <span v-else-if="scope.row.terminal == '2'">小程序</span>
                  <span v-else>App,小程序</span>
                </template>
              </el-table-column>
              <el-table-column
                label="行政区划"
                align="center"
                prop="areaName"
                show-overflow-tooltip
                min-width="150"
              ></el-table-column>
              <el-table-column
                label="发布状态"
                align="center"
                prop="areaName"
                show-overflow-tooltip
                min-width="150"
              >
                <template slot-scope="scope">
                  {{
                    $setDictionary(
                      "SYS_ADVISORY_STATE",
                      scope.row.advisoryState
                    )
                  }}
                </template></el-table-column
              >
               <el-table-column
                label="人气"
                align="center"
                prop="popularity"
                show-overflow-tooltip
                min-width="100"
              >
                <template slot-scope="{row}">
                    {{row.popularity || 0}}
                </template>
              </el-table-column>
              <el-table-column
                label="创建时间"
                align="center"
                prop="createTime"
                show-overflow-tooltip
                min-width="150"
              ></el-table-column>
              <el-table-column
                label="操作"
                align="center"
                width="160"
              >
                <template slot-scope="scope">
                  <el-button type="text" size="mini" @click="seeInfo(scope.row)"
                    >编辑</el-button
                  >
                  <el-button type="text" size="mini" v-if="scope.row.advisoryState == '10'" @click="advisoryStateSend(scope.row)"
                    >发布</el-button
                  >
                  <el-button type="text" size="mini" v-else @click="advisoryStateSend(scope.row)"
                    >回收</el-button
                  >
                  <el-button
                    type="text"
                    size="mini"
                    @click="deleteData(scope.row)"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <!-- 分页 -->
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue"; // 引入表格无数据提示 - 组件
import PageNum from "@/components/PageNum.vue"; // 引入分页 - 组件
import List from "@/mixins/List"; // 引入列表 - 混入
import { resetKeepAlive } from "@/utils/common"; // 缓存页面
export default {
  name: "operate/advisoryNotice",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      // 检索数据
      searchData: {
        title: "", // 标题
        terminal: "", //发布系统
        advisoryState:'', //发布状态
      },
      options: [
        {
          value: "1",
          label: "App",
        },
        {
          value: "2",
          label: "小程序",
        },
        {
          value: "1,2",
          label: "App,小程序",
        },
      ],
      advisoryOptions: [],
    };
  },
  mounted() {},
  computed: {},
  beforeRouteLeave: resetKeepAlive,
  watch: {
    $route: {
      handler: function (val, oldVal) {
        if (oldVal.meta.level == 2 && val.query.liuliuliu) {
          this.getData(-1);
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
  created() {
    this.getCodeValue();
  },
  methods: {
    // 获取数据
    getData(pageNum = 1) {
      const params = {
        advisoryName: this.searchData.title,
        pageNum: pageNum,
        pageSize: this.pageSize,
        terminal: this.searchData.terminal,
        advisoryState: this.searchData.advisoryState
        ,
      };
      this.doFetch({
        url: "/biz/advisory/queryAdvisoryList",
        params,
        pageNum,
      });
    },
    // 编辑
    seeInfo(row) {
      if (row) {
        this.$router.push({
          path: "/web/operate/aeAdvisoryNotice",
          query: {
            id: row.advisoryId,
          },
        });
      } else {
        this.$router.push("/web/operate/aeAdvisoryNotice");
      }
    },
    // 删除
    deleteData(row) {
      this.$confirm("是否确定删除资讯？此操作将永久删除信息。", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$post("/biz/advisory/delete", {
            advisoryId: row.advisoryId,
          }).then((res) => {
            this.$message.success('资讯删除成功!');
            this.getData();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //发布&&回收
    advisoryStateSend(row) {
      if (row.advisoryState == '10') {
        this.$post('/biz/advisory/state/modify',{advisoryId:row.advisoryId}).then(res => {
          if(res.status == 0) {
            this.$message.success('资讯发布成功!');
            this.getData(-1)
          }
        })
      } else {
         this.$confirm("是否确定回收资讯?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal: false,
      })
        .then(() => {
            this.$post('/biz/advisory/state/modify',{advisoryId:row.advisoryId}).then(res => {
          if(res.status == 0) {
            this.$message.success('资讯回收成功!');
            this.getData(-1)
          }
        })
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消回收",
          });
        });
      }
    },
    //获取码值
    getCodeValue() {
      const advisoryList = this.$setDictionary("SYS_ADVISORY_STATE", "list");
      for (const key in advisoryList) {
        this.advisoryOptions.push({
          value: key,
          label: advisoryList[key],
        });
      }
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight = window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 3) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
};
</script>

<style lang="less" scoped>
.advisoryNotice /deep/ .el-input__inner {
  height: 32px;
  line-height: 32px;
}

.advisoryNotice {
  .el-form {
    width: 100%;
  }
  .el-form-item {
    margin-bottom: 0;
    span {
      color: #909399;
      font-size: 12px;
    }
  }
}
</style>
<style>
.el-tooltip__popper {
  max-width: 30% !important;
}
</style>
